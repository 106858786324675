<template>
  <div class="promotion-list">
    <div v-loading="loading" class="promotion-list-wrapper slide-in-top">
        <div class="promotion-list-wrapper-header">
            <h1>רשימת מבצעים</h1>
            <div @click="$emit('close')" class="back-btn">
                <i class="material-icons back_icon">west</i>
            </div>
        </div>
        <div class="promotion-list-wrapper-tools">
            <el-input  style="width:30%; margin-right:5px;" v-model="search" placeholder="חיפוש" />
            <el-button @click="handle_reset_filters" style="margin:0 5px;" type="primary">נקה מסננים</el-button>
            <el-button @click="filters.current_deals_month=!filters.current_deals_month"  :type="filters.current_deals_month?'success':null">מבצעי החודש</el-button>
        </div>
        <div class="promotion-list-wrapper-filters">
            <el-button @click="handle_clean_checks_boxes" class="slide-in-right" v-if="selected_yedion_promos.length > 0" style="margin-right:5px;" type="danger">נקה בחירה</el-button>
            <el-button @click="handle_insert_promos_to_yedion" class="slide-in-right" v-if="selected_yedion_promos.length > 0" style="margin-right:5px;" type="success">הכנס לידיעון</el-button>
            <span>מתאריך:</span>
            <el-input v-model="filters.from_date_string" style="width:150px;" type="date" placeholder="Please input" />
            <span>עד תאריך:</span>
            <el-input v-model="filters.to_date_string" style="width:150px;" type="date" placeholder="Please input" />
            <el-button @click="handle_filter_by_dates" type="primary">סנן</el-button>
            <el-select filterable  v-model="selected_category" class="m-2" placeholder="בחר מחלקה" size="large">
                <el-option
                    v-for="cat in categories"
                    :key="cat"
                    :value="cat"
                />
            </el-select>
            <el-select style="margin-right:5px;" clearable  filterable v-model="selected_supplier" class="m-2" placeholder="ספק" size="large">
                <el-option
                v-for="sup in suppliers"
                :key="sup"
                :value="sup"
                />
            </el-select>
            <el-select filterable v-model="selected_branche"  class="m-2" placeholder="בחר סניף" size="large">
                <el-option
                    v-for="branche in branches"
                    :key="branche"
                    :value="branche"
                />
            </el-select>
        </div>
        <div class="promotion-list-wrapper-content">
            <table style="table-layout: fixed; width: 100%;" v-if="promos.length > 0">
                <tr>
                    <th style="width:50px;"></th>
                    <th style="cursor:pointer;" @click="handleSortTable('קוד', !selectedSorted.order)">קוד</th>
                    <th style="cursor:pointer;" @click="handleSortTable('ספק', !selectedSorted.order)">ספק</th>
                    <th style="cursor:pointer;" @click="handleSortTable('שם', !selectedSorted.order)">שם</th>
                    <th style="cursor:pointer;" @click="handleSortTable('סניפים', !selectedSorted.order)">סניפים</th>
                    <th style="cursor:pointer;" @click="handleSortTable('מחלקה', !selectedSorted.order)">מחלקה</th>
                    <th style="cursor:pointer;" @click="handleSortTable('מתאריך', !selectedSorted.order)">מתאריך</th>
                    <th style="cursor:pointer;" @click="handleSortTable('עד תאריך', !selectedSorted.order)">עד תאריך</th>
                </tr>
                <h1 v-if="promos.length == 0">לא קיימים רשומות</h1>
                <template v-for="(deal) in slice_list" :key="deal.promoCode">
                    <tr>
                        <td>
                            <input type="checkbox" :checked="isDealSelected(deal)" :value="deal.promoCode" @change="toggleDealSelection(deal)">
                        </td>
                        <td @click="stam(deal)">{{deal.promoCode}}</td>
                        <td>{{deal.supplier?deal.supplier:'לא צויין ספק'}}</td>
                        <td>{{deal.RemarkForPrint?deal.RemarkForPrint:'לא צויין'}}</td>
                        <td style="word-wrap: break-word;">{{deal.Stores?.length > 0?deal.Stores.join(','):'כולם'}}</td>
                        <td>{{deal.products?.length>0?deal.products[0].Department:'לא צויין'}}</td>
                        <td>{{new Date(deal.FromDate).toLocaleDateString('he')}}</td>
                        <td>{{new Date(deal.ToDate).toLocaleDateString('he')}}</td>
                    </tr>
                </template>
            </table>
        </div>
        <div class="shilut-deals-db-pagination">
            <el-pagination 
                v-model:currentPage="pagination.current_page"
                background 
                :page-size="pagination.number_per_page"
                layout="prev,pager,next"
                :total="sorted_list.length" 
            />
        </div>
    </div>
  </div>
</template>

<script>
import {selected_yedion_promos} from './store'
import {insert_news_promos_to_yedion} from './Script/script'
import {alert} from '../../../../../Methods/Msgs'
import {
    promos,
    categories,
    branches,
    suppliers
} from '../Deals/Scripts/script'
import { computed, ref } from 'vue'
export default {
    emits:['close','insert_submit'],
    setup(_,{emit}){
        
        const loading = ref(false)

        const stam = (deal) => {
            console.log(deal);
        }

        
        const isDealSelected = (deal) => {
            return selected_yedion_promos.value.some(i => i.promoCode === deal.promoCode);
        };
        const toggleDealSelection = (deal) => {
            const index = selected_yedion_promos.value.findIndex(i => i.promoCode === deal.promoCode);
            if(index !== -1){
                selected_yedion_promos.value = selected_yedion_promos.value.filter(({promoCode}) => promoCode != deal.promoCode );

            }else{
                selected_yedion_promos.value.push(deal);
            }
        }
        const selected_category = ref('')
        const selected_branche = ref('')
        const selected_supplier = ref('')
        
        const search = ref('')

        const pagination = ref({
            current_page:1,
            number_per_page:50,
        })

        const filters = ref({
            from_date_string:'',
            to_date_string:'',
            from_date:false,
            to_date:false,
            products:false,
            current_deals_month:false
        })
        
        const filter_by_supplier = ref(computed(() => {
            if(!selected_supplier.value || selected_supplier.value == 'הכל') return filter_by_branches.value
            return filter_by_branches.value.filter(deal => deal.products.length > 0 && deal.products[0].SupplierName == selected_supplier.value)
        }))

        const filter_by_categories = ref(computed(() => {
            if(!selected_category.value || selected_category.value == 'הכל') return filter_by_current_month.value
            return filter_by_current_month.value.filter(deal=>{
                if((deal?.products?.length > 0) && (deal?.products[0]?.Department == selected_category.value)) {
                    return deal
                }
            })
        }))

        const filter_by_branches = ref(computed(() => {
            if(!selected_branche.value || selected_branche.value == 'הכל') return filter_by_categories.value
            return filter_by_categories.value.filter(deal=>{
                if(deal?.Stores?.includes(selected_branche.value) || (deal?.Stores?.length == 1 && !deal?.Stores[0])) return deal
            })
        }))

        const filter_by_current_month = ref(computed(()=>{
            if(filters.value.current_deals_month){
                return filter_by_dates.value.filter(deal=>{
                    if(getCurrentMonth(deal.FromDate) == current_month.value && getCurrentYear(deal.FromDate) == curent_year.value) return deal
                })
            }
            return filter_by_dates.value
        }))

        const filter_by_dates = ref(computed(()=>{ 
            if(filters.value.products){
                return filter_by_search.value.filter(deal=>{
                    if(filters.value.products && deal.products.length == 0)return deal
                })
            }
            if(!filters.value.from_date && !filters.value.to_date) return filter_by_search.value
            return filter_by_search.value.filter(deal=>{
                if(filters.value.products && deal.products.length > 0) return deal
                if(filters.value.from_date && filters.value.to_date){
                    if(stripTimeFromDate(deal.FromDate) >= stripTimeFromDate(filters.value.from_date_string) && stripTimeFromDate(deal.ToDate) <= stripTimeFromDate(filters.value.to_date_string)){
                        return deal
                    }
                }
                else if(filters.value.from_date && !filters.value.to_date){
                    if(stripTimeFromDate(deal.FromDate) >= stripTimeFromDate(filters.value.from_date_string))
                    return deal
                }
                else if(filters.value.to_date && !filters.value.from_date){
                    if(stripTimeFromDate(deal.ToDate) <= stripTimeFromDate(filters.value.to_date_string)){
                        return deal
                    }
                }
            })
        }))

        const selectedSorted = ref({
            name: "",
            order: false
        });

        const slice_list = ref(computed(()=>{
            return sorted_list.value.slice((pagination.value.current_page -1 ) * pagination.value.number_per_page,
            ((pagination.value.current_page-1) * pagination.value.number_per_page) + pagination.value.number_per_page)
        }))
        
        const sorted_list = ref(computed(() => {
            if (selectedSorted.value.name == "")return filter_by_supplier.value;
            if (selectedSorted.value.name == "קוד") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  Number(a.promoCode) > Number(b.promoCode)
                        ? 1
                        : Number(b.promoCode)  > Number(a.promoCode)
                        ? -1
                        : 0;
                    }
                    return Number(a.promoCode) > Number(b.promoCode)
                    ? -1
                    : Number(b.promoCode)  > Number(a.promoCode)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "ספק") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a?.supplier > b?.supplier
                        ? 1
                        : b?.supplier  > a?.supplier
                        ? -1
                        : 0;
                    }
                    return a?.supplier > b?.supplier
                    ? -1
                    : b?.supplier  > a?.supplier
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a?.RemarkForPrint > b?.RemarkForPrint
                        ? 1
                        : b?.RemarkForPrint  > a?.RemarkForPrint
                        ? -1
                        : 0;
                    }
                    return a?.RemarkForPrint > b?.RemarkForPrint
                    ? -1
                    : b?.RemarkForPrint  > a?.RemarkForPrint
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סניפים") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a?.Stores.length > b?.Stores.length
                        ? 1
                        : b?.Stores.length  > a?.Stores.length
                        ? -1
                        : 0;
                    }
                    return a?.Stores.length > b?.Stores.length
                    ? -1
                    : b?.Stores.length  > a?.Stores.length
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מתאריך") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a?.FromDate) > new Date(b?.FromDate)
                        ? 1
                        : new Date(b?.FromDate)  > new Date(a?.FromDate)
                        ? -1
                        : 0;
                    }
                    return new Date(a?.FromDate) > new Date(b?.FromDate)
                    ? -1
                    : new Date(b?.FromDate)  > new Date(a?.FromDate)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "עד תאריך") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a?.ToDate) > new Date(b?.ToDate)
                        ? 1
                        : new Date(b?.ToDate)  > new Date(a?.ToDate)
                        ? -1
                        : 0;
                    }
                    return new Date(a?.ToDate) > new Date(b?.ToDate)
                    ? -1
                    : new Date(b?.ToDate)  > new Date(a?.ToDate)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחלקה") {
                return filter_by_supplier.value.sort((a, b) => {
                    const aProducts = a.products || [];
                    const bProducts = b.products || [];
                    if (aProducts.length === 0 && bProducts.length === 0) {
                        return 0;
                    } else if (aProducts.length === 0) {
                        return 1;
                    } else if (bProducts.length === 0) {
                        return -1;
                    } else {
                        if (selectedSorted.value.order) {
                            return (aProducts[0].Department > bProducts[0].Department)
                                ? 1
                                : bProducts[0].Department > aProducts[0].Department
                                    ? -1
                                    : 0;
                        }
                        return (aProducts[0].Department > bProducts[0].Department)
                            ? -1
                            : bProducts[0].Department > aProducts[0].Department
                                ? 1
                                : 0;
                    }
                    });
            }
        }));
        
        const current_month  = ref(computed(()=>{
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1;
            return currentMonth
        }))

        const curent_year = ref(computed(()=>{
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            return currentYear;
        }))

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };

        const filter_by_search = ref(computed(()=>{
            if(!search.value) return promos.value
            return promos.value.filter(deal=>{
                if(deal.products.length > 0 && deal.products.map((product)=>product.barcode).join().includes(search.value)) return deal
                if(deal.promoCode?.includes(search.value)) return deal
                if(deal.supplier?.includes(search.value)) return deal
                if(deal.RemarkForPrint?.includes(search.value)) return deal
                if(deal.Stores?.includes(search.value)) return deal
                if(new Date(deal.FromDate).toLocaleDateString('he').includes(search.value)) return deal
                if(new Date(deal.ToDate).toLocaleDateString('he').includes(search.value)) return deal
            })
        }))
        
        const handle_filter_by_dates = () => {
            if(filters.value.from_date_string){
                filters.value.from_date = true
            }else{
                filters.value.from_date = false
            }
            if(filters.value.to_date_string){
                filters.value.to_date = true
            }else{
                filters.value.to_date = false
            }
        }
        const handle_clean_checks_boxes = () => {
            selected_yedion_promos.value = []
        }
        const handle_reset_filters = () => {
            filters.value = {
                from_date_string:'',
                to_date_string:'',
                from_date:false,
                to_date:false,
                products:false,
                current_deals_month:false
            }
            search.value = ''
            selected_category.value = ''
        }

        const handle_insert_promos_to_yedion = async () => {
            try{
                loading.value = true
                await insert_news_promos_to_yedion()
                loading.value = false
                emit('insert_submit')
            }catch(err){
                loading.value = false
                alert('error','שגיאה',err.message)
            }
        }

        function stripTimeFromDate(dateString) {
            const date = new Date(dateString);
            date.setHours(0, 0, 0, 0);
            return date;
        }

        function getCurrentMonth(isoDate) {
            const currentDate = new Date(isoDate);
            const currentMonth = currentDate.getMonth() + 1;
            return currentMonth;
        }

        function getCurrentYear(isoDate) {
            const currentDate = new Date(isoDate);
            const currentYear = currentDate.getFullYear();
            return currentYear;
        }

        return{
            handle_reset_filters,
            handle_clean_checks_boxes,
            handle_filter_by_dates,
            handle_insert_promos_to_yedion,
            search,
            filters,
            selected_category,
            selected_yedion_promos,
            selected_branche,
            categories,
            branches,
            promos,
            filter_by_search,
            handleSortTable,
            pagination,
            stam,
            slice_list,
            isDealSelected,
            toggleDealSelection,
            selectedSorted,
            sorted_list,
            loading,
            selected_supplier,
            suppliers,
            
        }
    }
}
</script>

<style scoped>
    .promotion-list{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 5px;
        background: var(--main);
    }
    .promotion-list-wrapper{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
    }
    .promotion-list-wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .promotion-list-wrapper-tools,.promotion-list-wrapper-filters{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .promotion-list-wrapper-filters > *{
        margin: 0 5px;
    }
    .promotion-list-wrapper-content{
        width: 100%;
        height: calc(100% - 200px);
        overflow-y: auto;
    }

    .shilut-deals-db-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .back-btn{
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        background: var(--danger);
        cursor: pointer;
    }

    .slide-in-top {
	    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
    }

     .slide-in-right {
	    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
                    transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            opacity: 1;
        }
    }
    @keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
                    transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            opacity: 1;
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: lightseagreen !important;
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }

    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--success);
        color: #fff;
        
    }

</style>